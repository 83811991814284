.loginWrapper {
  padding-bottom: 2rem;
  padding-top: 10vh;
  h1 {
    margin: 2rem auto;
  }

  .card {
    padding-bottom: 2rem;
    color: black;
    hr {
      border-bottom: 1px solid black;
    }
  }
}
