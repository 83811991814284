.NewUserExperience {
  &.light {
    a {
      color: black !important;
    }
  }

  &.dark {
    a {
      color: white !important;
    }
  }
  padding-top: 5rem;

  .logos-container {
    text-align: center;
    display: flex;
    justify-content: space-between;
  }

  .new-ux-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid;
    margin-bottom: 20px;
    text-align: center;
  }

  .close-new-ux-banner {
    cursor: pointer;
  }

  @media only screen and (max-width: 720px) {
    padding-top: 1rem;

    .logos-container {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .home-logos-View {
      width: 150px;
      margin-top: 10px;
    }

    .home-logos-Create {
      width: 150px;
      margin-top: 10px;
    }

    .home-logos-Connect {
      width: 170px;
      height: 150px;
      margin-top: 10px;
    }

    .home-logos-caption {
      font-size: 20px;
    }
  }

  @media only screen and (min-width: 720px) and (max-width: 1160px) {
    padding-top: 1rem;
    
    .home-logos-View {
      width: 200px;
      margin-top: 30px;
    }

    .home-logos-Create {
      width: 200px;
      margin-top: 30px;
    }

    .home-logos-Connect {
      width: 220px;
      height: 200px;
      margin-top: 30px;
    }

    .home-logos-caption {
      font-size: 28px;
    }
  }

  @media only screen and (min-width: 1161px) {
    padding-top: 1rem;

    .home-logos-View {
      width: 350px;
      margin-top: 30px;
    }

    .home-logos-Create {
      width: 350px;
      margin-top: 30px;
    }

    .home-logos-Connect {
      width: 370px;
      height: 350px;
      margin-top: 30px;
    }

    .home-logos-caption {
      font-size: 30px;
    }
  }
}
