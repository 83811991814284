.Create {
  .petal {
    cursor: pointer;

    stroke: #000000;

    &.active {
      stroke: lightgreen;
    }

    &:hover {
      fill-opacity: 75%;
    }
  }

  svg {
    cursor: pointer;
    height: 296px;
  }

  .buttonRow {
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  .right-button {
    text-align: right;
  }

  .hue {
    input {
      background: transparent;
    }
  }
}
